import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import _throttle from 'lodash.throttle';
import { Typography } from 'common/components/Typography';
import { YandexEvent } from 'utils/counters/YandexEvent';

import { useMounted } from 'common/hooks/useMounted';
import { useTop100Context } from 'common/contexts/top100Context';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import { YANDEX_METRICS } from 'config/constants/counters';

import s from './styles.css';

const TABS = [
  {
    name: 'horoscopes',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/horoscopes.svg',
    label: 'Гороскопы',
    href: '/',
    ymClickName: 'tab_bar_horo_click',
  },
  {
    name: 'tarot',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/tarot.svg',
    label: 'Таро',
    href: '/taro/online/',
    ymClickName: 'tab_bar_taro_click',
  },
  {
    name: 'moon',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/moon.svg',
    label: 'Лунный',
    href: '/moon/calendar/',
    ymClickName: 'tab_bar_moon_click',
  },
  {
    name: 'hair',
    icon: 'https://static.rambler.ru/icons/horoscope/topline/regular/hair.svg',
    label: 'Стрижки',
    href: '/hair/',
    ymClickName: 'tab_bar_haircuts_click',
  },
];

// "чувствительность" скролла
const SCROLL_MIN_VALUE = 20;

export const TabBar: React.FC = React.memo(() => {
  const currentPath = useSelector((state: IAppState) => state.runtime.path);

  const rootRef = useRef<HTMLDivElement>(null);
  const isFirstVisibleRef = useRef(false);
  const prevScroll = useRef(0);

  const mounted = useMounted();

  const [isVisible, setIsVisible] = useState(false);

  const { top100Prefix } = useTop100Context();

  const calculateVisiblePosition = () => {
    const currentScroll = window.scrollY;

    // в верхнем положении всегда показываем
    if (currentScroll < SCROLL_MIN_VALUE) {
      setIsVisible(true);
    }

    // если скроллим вниз + погрешность на SCROLL_MIN_VALUE
    // чтобы не было скачков при скролле на пару пикселей
    if (
      currentScroll > SCROLL_MIN_VALUE &&
      currentScroll >= prevScroll.current + SCROLL_MIN_VALUE
    ) {
      setIsVisible(false);
    }

    // обратный скролл
    if (currentScroll < prevScroll.current) {
      setIsVisible(true);

      if (!isFirstVisibleRef.current) {
        new YandexEvent(YANDEX_METRICS.COMMON).send({
          type: 'reachGoal',
          data: 'tab_bar_horo_show',
        });
        isFirstVisibleRef.current = true;
      }
    }

    prevScroll.current = currentScroll;
  };

  const onScroll = _throttle(calculateVisiblePosition, 100, {
    trailing: false,
  });

  const tabOnClick = (targetName: string) => {
    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: targetName,
    });
  };

  useEffect(() => {
    if (mounted) {
      const node = rootRef.current;

      if (node) {
        window.addEventListener('scroll', onScroll);
        calculateVisiblePosition();
      }
    }

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  if (!mounted) {
    return null;
  }

  return (
    <div ref={rootRef} className={cn(s.root, isVisible && s.visible)}>
      <div className={s.content}>
        {TABS.map(({ name, label, href, icon, ymClickName }) => (
          <Typography
            key={name}
            className={cn(s.tab, href === currentPath && s.active)}
            variant="smallMedium"
            element={<Link to={href} onClick={() => tabOnClick(ymClickName)} />}
            {...getTop100Markup(
              true,
              `${top100Prefix}`,
              `tab_bar::link::${name}`,
            )}
          >
            <span
              className={s.icon}
              style={{ backgroundImage: `url(${icon})` }}
            />
            {label}
          </Typography>
        ))}
      </div>
    </div>
  );
});

TabBar.displayName = 'TabBar';
